// hooks/usePaginatedFirebaseData.ts (Actualizado para MRT)
import { useState, useEffect } from 'react';
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  where,
  onSnapshot,
  getFirestore,
} from 'firebase/firestore';

const usePaginatedFirebaseData = (
  collectionName,
  pagination,
  pageSize,
  globalFilter,
  columnFilters,
) => {
  const db = getFirestore();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastDocSnapshot, setLastDocSnapshot] = useState(null);

  useEffect(() => {
    setLoading(true);
    let unsubscribeData = () => {};
    let unsubscribeCount = () => {};

    const buildQuery = () => {
      let q = query(collection(db, collectionName), orderBy('created', 'desc'));

      // Aplicar filtros globales
   

      // Aplicar filtros de columnas
      columnFilters.forEach(filter => {
        q = query(q, where(filter.id, filter.operator, filter.value));
      });

      // Paginación
      q = query(q, limit(pageSize));
      if (pagination.pageIndex > 0 && lastDocSnapshot) {
        q = query(q, startAfter(lastDocSnapshot));
      }

      return q;
    };

    try {
      const q = buildQuery();

      // Listener de datos en tiempo real
      unsubscribeData = onSnapshot(q,
        (snapshot) => {
          const newData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          
          if (snapshot.docs.length > 0) {
            setLastDocSnapshot(snapshot.docs[snapshot.docs.length - 1]);
          }
          
          setData(newData);
          setLoading(false);
        },
        (err) => {
          setError(err.message);
          setLoading(false);
        }
      );

      // Listener de conteo total
      unsubscribeCount = onSnapshot(
        query(collection(db, collectionName)),
        (snapshot) => setTotalRows(snapshot.size),
        (err) => setError(err.message)
      );

    } catch (err) {
      setError(err.message);
      setLoading(false);
    }

    return () => {
      unsubscribeData();
      unsubscribeCount();
    };
  }, [collectionName, pagination.pageIndex, pageSize, globalFilter, columnFilters]);

  return { data, totalRows, loading, error };
};

export default usePaginatedFirebaseData;