import React, { useState } from 'react'
import { Box, Menu, MenuItem, IconButton, Avatar, ListItemIcon, Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
export const ProfileMenu = () => {
    const auth = getAuth();
     const navigate = useNavigate();
    const user = auth?.currentUser;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const logoutHandler = async () => {
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            alert(error);
        });
    }
    return (
        <>
            <IconButton
                onClick={handleClick}
                id="basic-button"
            >
                <Avatar src="" alt={user?.displayName} />
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                id="basic-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                elevation={1}
            >
                <MenuItem>
                    <ListItemIcon>
                        <Avatar />
                    </ListItemIcon>
                    <Box pl={2}>
                        <Typography>{user?.displayName}</Typography>
                        <Typography style={{ width: 200, textOverflow: 'ellipsis', overflow: 'hidden' }}> {user?.email}</Typography>
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => navigate('/accounts/users/')}>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    Usuarios
                </MenuItem>
                <MenuItem onClick={() => navigate('/accounts/posts/')}>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    Publicaciones
                </MenuItem>
                <MenuItem onClick={() => navigate('/accounts/dashboard/')}>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    Verificacion
                </MenuItem>
                <MenuItem onClick={logoutHandler}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    Cerrar sesión
                </MenuItem>
            </Menu>
        </>
    )
}