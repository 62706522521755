import React, { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { useParams } from 'react-router-dom'
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, CardContent, CardMedia, Container, Typography, IconButton, CardHeader, Avatar, Box, CircularProgress, Grid } from '@mui/material'
import Footer from '../Footer';
export default function PostsPage() {
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState(null)
  console.log(post);
  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, `users/${params?.username}/posts/${params?.postId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPost(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [params])

  return (
    <Box>
      <Box p={5}></Box>
      <Container maxWidth='sm'>
        {
          !loading ?
            <Card variant='outlined' >
              <CardHeader
                avatar={
                  post?.post_anon === true ? (
                    <Avatar src={post?.user_photo_thumbnail} aria-label={post?.username} />
                  ) : (<Avatar />
                  )
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={post?.post_anon === true ?  post?.username : 'Anónimo'}
                subheader={`${moment(post?.created?.toDate().toISOString()).fromNow()} - ${post?.post_province}`}
              />
              <CardContent>
                <Typography>{post?.post_description}</Typography>
              </CardContent>
              {
                post?.post_photo && 
                <CardMedia
                component="img"
                image={post?.post_photo}
                height={600}
                alt={post?.post_description}
              />
              }
              
              {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  <FavoriteBorderIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </CardActions> */}
            </Card>
            : <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '100vh' }}
            >
              <Grid item xs={3}>
                <CircularProgress />
              </Grid>
            </Grid>
        }
      </Container>
      <Footer />
    </Box>
  )
}