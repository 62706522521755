import React from 'react';
import { MenuItem, Select, FormControl } from '@mui/material';

const StatusDropdown = ({ currentStatus, onStatusChange }) => {
  const handleChange = (event) => {
    onStatusChange(event.target.value);
  };

  return (
    <FormControl size="small" variant="outlined">
      <Select
         value={currentStatus || 'pending'}
         onChange={handleChange}
         displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="pending">En Espera</MenuItem>
        <MenuItem value="approved">Aprobado</MenuItem>
        <MenuItem value="rejected">Rechazado</MenuItem>
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;