import React from 'react';
import {  Box, IconButton, Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VideoPlayerModal = ({ url, onClose }) => {
    return (
        <Dialog
            open={Boolean(url)}
            onClose={onClose}
            aria-labelledby="video-modal"
        >
            <DialogContent>
                <Box>
                    <IconButton
                        onClick={onClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <video
                        controls
                        autoPlay
                        style={{ width: '100%', outline: 'none' }}
                    >
                        <source src={url} type="video/mp4" />
                        Tu navegador no soporta videos HTML5
                    </video>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default VideoPlayerModal;