import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const TabsComponent = ({ activeTab, setActiveTab }) => {
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={activeTab} onChange={handleChange}>
        <Tab label="En Espera" value="pending" />
        <Tab label="Aprobadas" value="approved" />
        <Tab label="Rechazadas" value="rejected" />
      </Tabs>
    </Box>
  );
};
export default TabsComponent;