import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, updateDoc, doc, getFirestore, arrayRemove, arrayUnion } from 'firebase/firestore';

import TabsComponent from './TabsComponent';
import DataTableComponent from './DataTableComponent';

const DashboardRequests = () => {
  const db = getFirestore();
  const [activeTab, setActiveTab] = useState('pending');
  const [requests, setRequests] = useState([]);

  const fetchRequests = async (status) => {
    try {
      let q = query(collection(db, 'verifiedRequest'), where('status', '==', status));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        // Convertir timestamp de Firestore a fecha
        //created: doc.data().created?.toDate() || new Date()
      }));
      console.log(data)
      setRequests(data);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  useEffect(() => {
    fetchRequests(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleStatusUpdate = async (requestId, newStatus) => {
    try {
      console.log(requestId);
      const requestRef = doc(db, 'verifiedRequest', requestId);
      const userRef = doc(db, 'users', requestId);
      await updateDoc(requestRef, { status: newStatus });
      if (newStatus === 'approved') {
        await updateDoc(userRef, { roles: arrayUnion('verifyAccount') });
        await updateDoc(userRef, { planId: "4xYpcumsC9phPXSsTcJN" });
      }
      if (newStatus === 'rejected') {
        await updateDoc(userRef, { roles: arrayRemove('SendVerifyAccount') });
        await updateDoc(userRef, { planId: "" });
      }
      //
      fetchRequests(activeTab); // Refresh data
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div style={{ padding: '2rem' }}>
      <TabsComponent activeTab={activeTab} setActiveTab={setActiveTab} />
      <DataTableComponent
        data={requests}
        status={activeTab}
        onStatusUpdate={handleStatusUpdate}
      />
    </div>
  );
};

export default DashboardRequests;