import React from 'react';
import { Grid, Card, CardHeader, CardContent, List, ListItem, ListItemIcon, ListItemText, Typography, Box } from '@mui/material';
import { CheckCircleOutline as CheckIcon } from '@mui/icons-material';
import Footer from './Footer';
const plans = [
    {
        id: 'sin_verificar',
        name: 'Básico - Sin verificar',
        price: 'Gratis',
        features: [
            'Crea post de ideas',
            'Crea post de quejas',
            'Crea post anónimo',
            'Comenta en los posts de otros usuarios',
            'Comentarios de hasta 189 caracteres',
            'Filtra posts por Provincia',
            'Únete a grupos',
            'Participa en la competencia semanal de la mejor idea',
            'Contiene anuncios',
        ],
    },
    {
        id: 'basico',
        name: 'Básico - Verificado',
        price: 'Gratis',
        features: [
            'Contiene beneficios del Plan Básico - Sin verificar',
            'Visualiza reportes en el mapa de hasta 24 horas de antigüedad',
            'Acepta o rechaza mensajes entrantes en chats',
            'Participa en el sistema de recompensas DOGE',
            'Acceso a servicio al cliente',
            'Contiene anuncios',
          
        ],
    },
    {
        id: 'premium',
        name: 'Premium',
        price: '$5/mes',
        features: [
            'Se requiere ser usuario verificado',
            'Contiene beneficios del Plan Básico',
            'Edita publicaciones',
            'Comentarios de hasta 1989 caracteres',
            'Acepta o rechaza solicitudes de amistad',
            'Crea grupos',
            'Crea encuestas',
            'Realiza encuestas de 1 pregunta y hasta 5 opciones de selección múltiple',
            'Encuestas de hasta 24 horas',
            'Visualiza reportes de hasta 7 dias en el mapa',
            'Respuesta de servicio al cliente en 72 horas',
            'Contiene anuncios',
        ],
    },
    {
        id: 'elite',
        name: 'Elite',
        price: '$16/mes',
        highlighted: true,
        features: [
            'Se requiere ser usuario verificado',
            'Contiene beneficios del Plan Premium',
            'Realiza encuestas de hasta 5 preguntas con 5 opciones cada una',
            'Encuestas de hasta 7 días',
          
            'Visualiza reportes de hasta 1 año de antigüedad en el mapa',
            'Respuesta de servicio al cliente en 24 horas',
            'Sin anuncios',
            'Crea anuncios',
        ],
    },
];
const PlanPage = () => {
    return (
        <Box sx={{ paddingX: 4 }}>
            <Box p={5}></Box>
                <Typography variant="h3" align="center" gutterBottom>
                    <strong>
                        Premium vs Elite
                    </strong>
                </Typography>
                <Typography align="center" gutterBottom>
                    ¡Hola! Prepárate para disfrutar de una experiencia mejorada llena de herramientas especiales solo para tí. Con Polivoz Premium y Elite podrás conectar fácilmente con más personas en menos tiempo. ¡Disfrútalo!
                </Typography>
                <Box p={4}></Box>
                <Grid container spacing={4} justifyContent="center">
                    {plans.map((plan) => (
                        <Grid item key={plan.id} xs={12} sm={6} md={3}>
                            <Card sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                border: plan.highlighted ? '2px solid #1976d2' : 'none',
                                boxShadow: plan.highlighted ? 3 : 1
                            }}>
                                <CardHeader
                                    title={plan.name}
                                    subheader={plan.price}
                                    titleTypographyProps={{ align: 'center', variant: 'h5' }}
                                    subheaderTypographyProps={{ align: 'center', variant: 'h6' }}
                                    sx={{ backgroundColor: plan.highlighted ? '#f0f4ff' : 'inherit' }}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <List>
                                        {plan.features.map((feature, index) => (
                                            <ListItem key={index}>
                                                <ListItemIcon sx={{ minWidth: 30 }}>
                                                    <CheckIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={feature} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                                {/* <Box sx={{ p: 2 }}>
                                <Button
                                    fullWidth
                                    variant={plan.highlighted ? 'contained' : 'outlined'}
                                    color="primary"
                                >
                                    Seleccionar Plan
                                </Button>
                            </Box> */}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
      
            <Footer />
        </Box>
    );
};

export default PlanPage;