import React from 'react'
import { Container, Typography, Box, Stack, Link } from '@mui/material'
import Footer from './Footer'

export default function FAQPage() {
    return (
        <>
            <Container maxWidth='sm'>
                <Box p={5}></Box>
                <Stack spacing={5}>
                    <Typography textAlign='center' variant="h3" gutterBottom>
                        <strong>Preguntas Frecuentes</strong>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        ¿Por qué Polivoz necesita mi correo electrónico?
                    </Typography>
                    <Typography paragraph>
                        Usamos su correo electrónico para configurar y validar su cuenta de usuario y para evitar el abuso de la plataforma. Cualquier persona que viole nuestros Términos y Condiciones de uso o nuestra Política de Privacidad, o participe en conductas como acoso, discriminación o conducta ilegal puede ser excluida de Polivoz.
                    </Typography>
                    <Typography paragraph>
                        Para obtener más información sobre cómo usamos y protegemos sus datos, consulte nuestra{' '}
                        <Link href="/policy" color="secondary">
                            Política de Privacidad
                        </Link>.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        ¿Cómo verifico mi cuenta?
                    </Typography>
                    <Typography paragraph>
                        Para verificar su cuenta en Polivoz, asegúrese de tener la última versión de Polivoz y luego vaya a su Perfil {'>'} Verificar cuenta.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        ¿Qué es la Verificación con video y voz?
                    </Typography>
                    <Typography paragraph>
                        La Verificación con video y voz es un proceso opcional. Después de una Verificación con video y voz exitosa, obtendrá una insignia en su perfil, para mostrarles a las personas que ha completado el proceso de Verificación y además tendrá acceso a participar en la rifa de DOGE que se realiza cada mes.
                    </Typography>
                    <Typography paragraph>
                        Con la Verificación, también podrá ver si otros perfiles de Polivoz han completado el proceso de Verificación. De esa manera, podrá tomar decisiones más informadas sobre con quién interactúa en Polivoz.
                    </Typography>
                    {/* */}
                    <Typography variant="h6" gutterBottom>
                        ¿Cómo se utiliza la información de mi Verificación?
                    </Typography>
                    <Typography paragraph>
                        Sus datos de geometría facial y voz solo se utilizan para fines de la Verificación. Su video “selfie” no se almacena, y se elimina inmediatamente después de completar la Verificación. Las tres capturas de pantalla que tomamos de su video “selfie” se utilizan únicamente con el propósito de auditar y administrar esta función.
                    </Typography>
                    <Typography paragraph>
                        Para realizar el proceso de Verificación, almacenamos y utilizamos sus datos de geometría facial y capturas de pantalla en nuestras instancias de Google Cloud. Esta información y capturas de pantalla se eliminan de nuestras instancias de Google Cloud en los plazos que se detallan en ¿Qué información guardarán después de la Verificación?
                    </Typography>
                    {/* */}
                    <Typography variant="h6" gutterBottom>
                        ¿Qué información guardarán después de la Verificación con foto?
                    </Typography>
                    <Typography paragraph>
                        Su información de geometría facial y voz se conserva después de la Verificación inicial para que podamos continuar brindándole el servicio de Verificación a medida que cambia y actualiza su foto de perfil a lo largo del tiempo.  Para ello, sus datos de geometría facial se conservan durante toda la vida de su cuenta y se eliminan de nuestros sistemas dentro de los 30 días posteriores al cierre de su cuenta, de acuerdo con nuestra Política de Privacidad.
                    </Typography>
                    <Typography paragraph>
                        También guardaremos tres capturas de pantalla del proceso del video “selfie”. Conservamos esta información durante la vida de su cuenta para auditar y administrar nuestra función de Verificación. Además, conservaremos los resultados del proceso de Verificación (haya sido exitoso o no) hasta que elimine su cuenta de Polivoz, o alternativamente, hasta que su cuenta de Polivoz se cierre después de un año de inactividad. Como indicamos anteriormente, no guardamos su video “selfie”, que se elimina después de completar el proceso de Verificación.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        ¿Por qué necesito habilitar la ubicación y las notificaciones?
                    </Typography>
                    <Typography paragraph>
                        La misión de Polivoz es hacer de su país un lugar más seguro. Le pedimos que habilite sus permisos de ubicación y notificaciones para que Polivoz pueda trabajar en segundo plano, incluso cuando no esté usando la aplicación, para enviarle alertas en tiempo real que, a veces, pueden salvarle la vida.
                    </Typography>
                    <Typography paragraph>
                        Para obtener ayuda para solucionar problemas con estas configuraciones, consulte{' '}
                        <Link href="/policy" color="secondary">
                            ¿Cómo habilito las notificaciones y la ubicación?
                        </Link>.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        ¿Por qué no puedo registrarme en Polivoz?
                    </Typography>
                    <Typography paragraph>
                        Es posible que no pueda registrarse en Polivoz si se encuentra fuera de nuestras áreas de servicio.
                    </Typography>
                    <Typography paragraph>
                        Actualmente, Polivoz está disponible en Azuay, Bolívar, Cañar, Carchi, Chimborazo, Cotopaxi, El Oro, Esmeraldas, Galápagos, Guayas, Imbabura, Loja, Los Ríos, Manabí, Morona Santiago, Napo, Orellana, Pastaza, Pichincha, Santa Elena, Santo Domingo de los Tsáchilas, Sucumbíos, Tungurahua, y Zamora Chinchipe.
                    </Typography>
                    <Typography paragraph>
                        ¡Estamos comprometidos a expandir el alcance de Polivoz y estamos trabajando activamente para que nuestros servicios estén disponibles en mercados adicionales, incluidos América Central y del Sur! ¡Esté atento para recibir actualizaciones sobre cuándo podríamos estar disponibles en su área!
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        ¿Qué dispositivos son compatibles con Polivoz?
                    </Typography>
                    <Typography paragraph>
                        La versión más reciente de la aplicación Polivoz está diseñada para funcionar sin problemas en dispositivos móviles con iOS 9 o posterior y versiones de Android 7.0 (Nougat) y superiores. Esto garantiza que los usuarios puedan acceder a todas las últimas funciones y mejoras que ofrece Polivoz.
                    </Typography>
                    <Typography paragraph>
                        Si bien Polivoz está optimizada principalmente para teléfonos inteligentes, es posible acceder a la aplicación en iPads u otros dispositivos tipo tableta. Sin embargo, es importante tener en cuenta que estas tabletas no son compatibles oficialmente con Polivoz. Los usuarios que intenten usar la aplicación en dichos dispositivos pueden experimentar fallas o un comportamiento inusual, lo que podría afectar su experiencia general. Para obtener el mejor rendimiento y funcionalidad, se recomienda usar Polivoz en un teléfono móvil compatible.
                    </Typography>
                    {/* */}
                    <Typography variant="h6" gutterBottom>
                        ¿Cómo reportar un incidente en la aplicación Polivoz?
                    </Typography>
                    <Typography paragraph>
                        Si presencia un delito, es víctima de uno o se encuentra en una situación de emergencia, es fundamental que primero llame al 911 para recibir asistencia inmediata de las autoridades locales.
                    </Typography>
                    <Typography paragraph>
                        La aplicación Polivoz ofrece una poderosa plataforma para compartir actualizaciones en vivo para mejorar la seguridad de su país. Para proporcionar evidencia visual de incidentes o eventos importantes que se están desarrollando, puede utilizar la función “Deseo reportar…” de la aplicación. Simplemente toque el ícono de lápiz ubicado en la parte inferior derecha de la pantalla de inicio dentro de la aplicación Polivoz para iniciar su reporte.
                    </Typography>
                    <Typography paragraph>
                        Se le recomienda que informe una amplia gama de incidentes a través de la aplicación Polivoz, como: Accidente, Actividad Sospechosa, Disturbios, Drogas, Incendio, Quejas, Robo, Secuestro, Otros (actualizaciones generales de la comunidad o información importante que pueda beneficiar a los demás)
                    </Typography>
                    <Typography paragraph>
                        Si tiene alguna pregunta o necesita más ayuda, no dude en comunicarse por correo electrónico a soporte@polivoz.com
                    </Typography>
                    {/* */}
                    {/* */}
                    <Typography variant="h6" gutterBottom>
                        ¿Qué debo incluir en mi publicación de reporte?
                    </Typography>
                    <Typography paragraph>
                        Para los reportes debes incluir texto. Si desea puede agregar una foto (no se permite subir imágenes). Adicionalmente, puede realizar el reporte de forma anónima.
                    </Typography>
                    <Typography paragraph>
                        Tenga en cuenta que Polivoz evaluará los reportes de forma periódica y aleatoria para garantizar el correcto uso de esta funcionalidad.
                    </Typography>

                    {/* */}
                    {/* */}
                    <Typography variant="h6" gutterBottom>
                        ¿Cuáles son las clases de ideas que se pueden publicar en Polivoz?
                    </Typography>
                    <Typography paragraph>
                        Polivoz siempre busca ofrecer soluciones y mejorar la conciencia de los ciudadanos. Para compartir una idea use la función “Ideas para disminuir…” de la aplicación. Simplemente toque el ícono de bombillo ubicado en la parte inferior izquierda de la pantalla de inicio dentro de la aplicación Polivoz para iniciar su publicación.
                    </Typography>
                    <Typography paragraph>
                        En este momento, informamos sobre un subconjunto de 9 clases de ideas para mejorar la calidad de vida de su país. Estas son ideas para disminuir: Corrupción, Basura, Delincuencia, Desempleo, Analfabetismo, Migración, Drogadicción, Pobreza, Otros.
                    </Typography>
                    {/* */}
                    {/* */}
                    <Typography variant="h6" gutterBottom>
                        ¿Qué debo incluir en mi publicación de idea?
                    </Typography>
                    <Typography paragraph>
                        Para las ideas debes incluir texto. Adicionalmente, puede publicar su idea de forma anónima.
                    </Typography>
                    <Typography paragraph>
                        Tenga en cuenta que Polivoz evaluará las publicaciones de ideas de forma periódica y aleatoria para garantizar el correcto uso de esta funcionalidad.
                    </Typography>
                    {/* */}
                    {/* */}
                    <Typography variant="h6" gutterBottom>
                        ¿Cómo puedo reestablecer mi contraseña?
                    </Typography>
                    <Typography paragraph>
                        Si ha perdido el acceso a su cuenta de Polivoz porque has olvidado su contraseña, ¡no se preocupes! Puede restablecerla rápidamente siguiendo estos pasos detallados:
                    </Typography>
                    <Typography paragraph>
                       1.	Accede a la página de bienvenida de la app Polivoz y pulse el botón "Ya tengo cuenta”. Esto lo llevará a la pantalla de Iniciar Sesión. 
                    </Typography>
                    <Typography paragraph>
                    2.	En la parte inferior de la pantalla de Iniciar Sesión, pulse sobre el texto: ¿Olvidaste tu contraseña? Esto lo llevará a la pantalla de verificación de correo electrónico. 
                    </Typography>
                    <Typography paragraph>
                    3.	Proporcione su correo electrónico y pulse sobre el botón Continuar. 
                    </Typography>
                    <Typography paragraph>
                    4.	Se desplegará el siguiente mensaje: !Si te has creado cuenta en Polivoz se enviará un correo electrónico para restablecer la contraseña! 
                    </Typography>
                    <Typography paragraph>
                    5.	Pulsa el botón aceptar.
                    </Typography>
                    <Typography paragraph>
                    6.	Ingrese a su correo electrónico y busque en la bandeja de entrada o en la carpeta de correo no deseado un email proveniente de la dirección de correo soporte@polivoz.com 
                    </Typography>
                    <Typography paragraph>
                    7.	Acceda al enlace proporcionado en el correo enviado desde soporte@polivoz.com
                    </Typography>
                    <Typography paragraph>
                    8.	Ingrese su nueva contraseña y pulse el botón GUARDAR.
                    </Typography>
                    {/* */}
                         {/* */}
                    <Typography variant="h6" gutterBottom>
                    •	¿Cómo elimino mi cuenta de Polivoz?
                    </Typography>
                    <Typography paragraph>
                    Para eliminar su cuenta y la información registrada en Polivoz, asegúrese de tener la última versión de Polivoz y luego vaya a su Perfil {'>'} Eliminar Cuenta.
                    </Typography>
                    <Typography paragraph>
                    Si tiene alguna dificultad al intentar eliminar su cuenta, o si prefiere gestionar la solicitud por correo electrónico, póngase en contacto con nosotros en soporte@polivoz.com. En su correo electrónico, asegúrese de incluir el nombre de usuario y la dirección de correo electrónico que utilizó originalmente para registrarse en Polivoz. Nuestro equipo le responderá a la brevedad posible para ayudarle con el proceso de eliminación de la cuenta. ¡Gracias por utilizar Polivoz!
                    </Typography>
                    
                         {/* */}
                </Stack>
            </Container>
            <Footer />
        </>
    )
}