import React, { useState } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import InputValidation from '../../../components/InputValidation';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
// Función para mapear errores de Firebase a mensajes amigables
const getFirebaseError = (errorCode) => {
  const errors = {
    'auth/invalid-email': 'Correo electrónico inválido',
    'auth/user-disabled': 'Cuenta deshabilitada',
    'auth/user-not-found': 'Usuario no registrado',
    'auth/wrong-password': 'Contraseña incorrecta',
    'auth/too-many-requests': 'Demasiados intentos fallidos. Intente más tarde'
  };
  return errors[errorCode] || 'Error al iniciar sesión';
};
export const LoginPage = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState({ campo: '', valido: null });
  const [password, setPassword] = useState({ campo: '', valido: null });
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email.campo, password.campo);
      navigate('/accounts/dashboard/');
    } catch (error) {
      console.error('Error de autenticación:', error);
      setError(getFirebaseError(error.code));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth='xs'>
      <Box p={5}>
        <Stack spacing={2}>
          <Typography textAlign='center' variant='h5' component='h1'>
            Iniciar sesión
          </Typography>
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <InputValidation
              estado={email}
              cambiarEstado={setEmail}
              type="email"
              label="Correo electrónico"
              name="email"
              helperText="Acceso exclusivo para el personal administrativo de Polivoz."
              patterns={/^[a-zA-Z0-9._%+-]+@polivoz\.com$/}
              disabled={loading}
              required
            />
            <InputValidation
              estado={password}
              cambiarEstado={setPassword}
              type="password"
              label="Contraseña"
              name="password"
              //helperText="Mínimo 8 caracteres"
              //patterns={/^.{8,30}$/}
              disabled={loading}
              required
            />
            <Button
              variant="contained"
              fullWidth
              disableElevation
              type="submit"
              disabled={loading || email.valido ===  false}
              sx={{ mt: 2 }}
            >
              {loading ? 'Cargando...' : 'Iniciar sesión'}
            </Button>
          </form>
        </Stack>
      </Box>
    </Container>
  );
};