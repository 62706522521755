
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logoicon from '../assets/logo192.png'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileMenu } from './navbar/ProfileMenu';
import { getAuth } from 'firebase/auth';
const pages = [
    { nombre: 'Misión y Visión', rote: '/mision-vision' },
    { nombre: 'Preguntas Frecuentes', rote: 'faq' },
    { nombre: 'Planes', rote: '/planes' },
    { nombre: 'Política de Privacidad', rote: '/policy' }
];
const settings = [];
export default function Navbar() {
    const auth = getAuth();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = (rote) => {
        if (rote) {
            navigate(rote);
        }
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <AppBar color='transparent' elevation={0} position="static">
            <Container maxWidth="md">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={() => handleCloseNavMenu(null)}
                            sx={{ color: 'black', display: { xs: 'block', md: 'none' } }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.nombre} onClick={() => handleCloseNavMenu(page.rote)}>
                                    <Typography style={{ color: 'black' }}>{page.nombre}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <div onClick={() => navigate('/')} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                        <img src={logoicon} width="25%" alt='logo polivoz' />
                    </div>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.nombre}
                                onClick={() => handleCloseNavMenu(page.rote)}
                                sx={{ my: 2, display: 'block' }}
                            >
                                {page.nombre}
                            </Button>
                        ))}

                    </Box>
                    {
                        auth?.currentUser && <ProfileMenu />
                    }
                    <Box sx={{ flexGrow: 0 }}>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}