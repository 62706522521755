import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import StatusDropdown from './StatusDropdown';
import { Avatar, Chip, Link, Tooltip } from '@mui/material';
import VideoPlayerModal from './VideoPlayerModal';
const DataTableComponent = ({ data, status, onStatusUpdate }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (url) => {
    const isQuickTime = url?.toLowerCase().endsWith('.mov');
    if (isQuickTime) {
      window.open(url, '_blank'); // Forzar descarga o abrir en nueva pestaña
    } else {
      setSelectedVideo(url); // Mostrar modal para MP4
    }
  };
  const columns = [
    {
      field: 'user_photo_thumbnail',
      headerName: 'Foto',
      width: 80,
      renderCell: (params) => <Avatar src={params.value} />
    },
    { field: 'username', headerName: 'Usuario', width: 150 },
    {
      field: 'video',
      headerName: 'Video',
      width: 100,
      renderCell: (params) => {
        const url = params.value;
        const isQuickTime = url?.toLowerCase().endsWith('.mov');

        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Link
              onClick={() => handleVideoClick(url)}
              sx={{ cursor: 'pointer' }}
            >
              {isQuickTime ? 'Descargar Video' : 'Ver Video'}
            </Link>

            {isQuickTime && (
              <Tooltip title="Formato QuickTime - Recomendamos descargar para visualizar">
                <Chip
                  label="MOV"
                  size="small"
                  color="warning"
                  variant="outlined"
                />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      field: 'created',
      headerName: 'Fecha',
      width: 200,
      valueFormatter: (params) => new Date(params.seconds * 1000).toLocaleString() || 'N/A'
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 200,
      renderCell: (params) => (
        status === 'pending' && (
          <StatusDropdown
            currentStatus={params.row.status}
            onStatusChange={(newStatus) => onStatusUpdate(params.row.id, newStatus)}
          />
        )
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%', marginTop: '1rem' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
      <VideoPlayerModal 
        url={selectedVideo} 
        onClose={() => setSelectedVideo(null)}
      />
    </div>
  );
};

export default DataTableComponent;