import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MRT_Localization_ES } from 'material-react-table/locales/es'; // Español
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button, Card, IconButton, Tooltip } from '@mui/material';

const DataGridComponent = ({
  columns,
  data,
  loading,
  error,
  totalRows,
  pagination,
  sorting,
  setGlobalFilter,
  globalFilter,
  setSorting,
  setPagination,
  setFilters,
  rowActions,
  enableRowActions = false,
  onPaginationChange
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    manualPagination: true,
    rowCount: totalRows,
    initialState: { pagination },
    onPaginationChange,
    state: {
      pagination,
      isLoading: loading,
      showProgressBars: loading,
    },
    localization: MRT_Localization_ES,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        {rowActions?.map((action, index) => (
          <Tooltip key={index} title={action.tooltip}>
            <IconButton
              color={action.color || 'primary'}
              onClick={() => action.onClick(row.original)}
              disabled={action.disabled?.(row.original)}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Box>
    ),
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
    >
      <div style={{ height: 100, width: '100%' }}>
        {error && <div>Error: {error}</div>}
        <Card>
          <MaterialReactTable
            table={table}
          />
        </Card>
      </div>
    </LocalizationProvider>
  );
};
export default DataGridComponent;