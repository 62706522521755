import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import Footer from '../Footer'
import DashboardRequests from './verified-request/DashboardRequests';
export default function Dashboard() {
    return (
        <>
            <Container maxWidth='lg'>
                <Box p={2}></Box>
                <Typography textAlign='center' variant='h4' component='h1'>
                    <strong>Solicitud de Verificar Cuenta</strong>
                </Typography>
                <DashboardRequests />
            </Container>
            <Footer />
        </>
    )
}