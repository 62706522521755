import React, { useState } from 'react'
import { Container, Typography, Box } from '@mui/material'
import DataGridComponent from '../../components/datagrid/DataGridComponent';
import usePaginatedFirebaseData from '../../components/datagrid/usePaginatedFirebaseData';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { format } from 'date-fns';
import provincia_list from '../../assets/provincia_list.json'
import { Add, Delete } from '@mui/icons-material';
export default function PublicacionesPage() {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState({});
    const handleDelete = async (requestId) => {
        console.log(requestId);
        try {
            if (window.confirm("¿Quieres eliminar la publicacion?")) {
                await deleteDoc(doc(db, "posts", requestId))
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    const usersColumns = [
        {
            accessorFn: (row) =>
                row.created?.seconds ? new Date(row.created.seconds * 1000) : null,
            id: "created",
            header: "Fecha de Creación",
            filterVariant: "date",
            filterFn: "lessThan",
            sortingFn: "datetime",

            Cell: ({ cell }) => {
                const date = cell.getValue();
                return date ? format(date, "dd/MM/yyyy HH:mm") : "Sin fecha";
            },
            size: 50,
        },
        {
            accessorKey: 'username', size: 50, header: 'Username', headerAlign: 'center',
            align: 'center'
        },

        {
            accessorKey: 'post_province',
            header: 'Provincia',
            filterVariant: 'select',
            filterSelectOptions: provincia_list,
        },
        {
            accessorKey: 'post_category', size: 50, header: 'Categoria', headerAlign: 'center',
            align: 'center'
        },
        {
            accessorKey: 'post_description', size: 50, header: 'Descripcion', headerAlign: 'center',
            align: 'center',
            grow: false
        },
        {
            accessorKey: 'post_thumbnail',
            header: 'Imagen',
            size: 1,
            headerAlign: 'center',
            align: 'center',
            Cell: ({ cell }) => (
                cell?.getValue() &&
                <img
                    src={cell.getValue()}
                    alt="Miniatura"
                    height={50}
                    loading="lazy"
                    onClick={() => window.open(cell.getValue(), '_blank')}
                />
            ),
        },
    ];
    const tabs = [
        {
            label: 'Usuarios',
            collectionName: 'posts',
            columns: usersColumns
        }
    ];
    const { data, loading, error, totalRows } = usePaginatedFirebaseData(
        tabs[0].collectionName,
        pagination,
        globalFilter,
    filters,
    sorting
    );
    const globalActions = [
        {
          type: 'global',
          label: 'Nuevo Usuario',
          icon: <Add />,
          color: 'success',
          onClick: () => console.log('Crear nuevo usuario'),
        }
      ];
    
      // Acciones por fila
      const rowActions = [
        {
          type: 'row',
          tooltip: 'Eliminar usuario',
          icon: <Delete />,
          color: 'error',
          onClick: (user) => handleDelete(user?.id),
          disabled: (user) => user.role === 'admin',
        },
        
      ];
    return (
        <>
            <Container maxWidth='xl'>
                <Box p={2}></Box>
                <Typography variant='h4' gutterBottom component='h1'>
                    <strong>Listado de publicaciones</strong>
                </Typography>
                
            </Container>
        </>
    )
}