import { BrowserRouter, Route, Routes } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Home from "./page/Home";
import PostsPage from "./page/posts/PostsPage";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from "@stripe/react-stripe-js";
import Action from "./page/accounts/Actions";
import VerifyEmail from "./page/accounts/VerifyEmail";
import Navbar from "./components/Navbar";
import MisionVision from "./page/MisionVision";
import DeleteAccount from "./page/accounts/DeleteAccount";
import PolicyPage from "./page/PolicyPage";
import FAQPage from "./page/FAQPage";
import { LoginPage } from "./page/accounts/login/LoginPage";
import Dashboard from "./page/admin/Dashboard";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import PrivateRoute from "./routers/PrivateRoute";
import PublicRoute from "./routers/PublicRoute";
import PlanPage from "./page/PlanPage";
import 'moment/locale/es'
import UsersPage from "./page/admin/users/UsersPage";
import PublicacionesPage from "./page/admin/PublicacionesPage";
const stripePromise = loadStripe("pk_test_Jrx46GvieCTTAuc7rJ9NiKiC00jdBAPFfH");
const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};
function App() {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      //console.log(userAuth, 'userAuth');
      if (userAuth?.displayName) {
        //console.log('Si esta logeado');
        setIsAuth(true);
      }
    });
  }, [setIsAuth])
  return (
    <Elements stripe={stripePromise} options={options}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={
              <Home />
          } />
          <Route path='/policy' element={<PolicyPage />} />
          <Route path='/faq' element={<FAQPage />} />
          <Route path='/planes' element={<PlanPage />} />
          <Route path='/mision-vision' element={<MisionVision />} />
          <Route path='/delete-account' element={<DeleteAccount />} />
          <Route path='accounts'>
            <Route path='dashboard' element={
              <PrivateRoute isAuth={isAuth}>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path='users' element={
              <PrivateRoute isAuth={isAuth}>
                <UsersPage />
              </PrivateRoute>
            } />
             <Route path='posts' element={
              <PrivateRoute isAuth={isAuth}>
                <PublicacionesPage />
              </PrivateRoute>
            } />
            <Route path='login' element={
              <PublicRoute isAuth={isAuth}>
                <LoginPage />
              </PublicRoute>
            } />
            <Route path="action" element={<Action />} />
            <Route path="verifyemail" element={<VerifyEmail />} />
          </Route>
          <Route path='/:username/posts/:postId' element={<PostsPage />} />
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}
export default App;