import React, { useState } from 'react'
import { Container, Typography, Box } from '@mui/material'
import DataGridComponent from '../../../components/datagrid/DataGridComponent';
import usePaginatedFirebaseData from '../../../components/datagrid/usePaginatedFirebaseData';
import { format } from "date-fns";
import provincia_list from '../../../assets/provincia_list.json'
export default function UsersPage() {
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });
    const [filters, setFilters] = useState({});

    const usersColumns = [
        {
            accessorFn: (row) =>
                row.created?.seconds ? new Date(row.created.seconds * 1000) : null,
            id: "created",
            header: "Fecha de Creación",
            filterVariant: "date",
            filterFn: "lessThan",
            sortingFn: "datetime",
            Cell: ({ cell }) => {
                const date = cell.getValue();
                return date ? format(date, "dd/MM/yyyy HH:mm") : "Sin fecha";
            },
        },
        { accessorKey: 'username', width: 150, header: 'Username' },
        { accessorKey: 'nombres', width: 150, header: 'Nombre' },
        { accessorKey: 'apellidos', width: 150, header: 'Apellidos' },
        {
            accessorKey: 'provincia',
            header: 'Provincia',
            filterVariant: 'select',
            filterSelectOptions: provincia_list,
        },
        { accessorKey: 'correo', width: 200, header: 'Correo electrónico' },

    ];
    const tabs = [
        {
            label: 'Usuarios',
            collectionName: 'users',
            columns: usersColumns
        }
    ];
    const { data, loading, error, totalRows } = usePaginatedFirebaseData(
        tabs[0].collectionName,
        pagination,
        globalFilter,
        filters,
        sorting
    );
   
    return (
        <>
            <Container maxWidth='xl'>
                <Box p={2}></Box>
                <Typography variant='h4' gutterBottom component='h1'>
                    <strong>Listado de los usuarios</strong>
                </Typography>
                <DataGridComponent
                    columns={tabs[0].columns}
                    data={data}
                    
                />
            </Container>
        </>
    )
}