import React from 'react'
import { Container, Typography, Box, Grid } from '@mui/material'
import logo from '../assets/titulonuevo.png'
import btnappstore from '../assets/icons/btnappstore.svg'
import btnplaystore from '../assets/icons/btnplaystore.svg'
import Footer from './Footer'
export default function Home() {
    return (
        <Container>
            <Box p={5}></Box>
            <img style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '80%'
            }} src={logo} alt='polivoz' />
            {/*   <Typography align='center' variant='h1'><strong>Polivoz</strong></Typography> */}
            <Typography align='center' color='grey' variant='h4'><strong>De las quejas a las ideas. Tu voz, tu poder.</strong></Typography>
            <Typography align='center' color='grey' variant='h4'><strong>¡Únete a Polivoz y lleva a Ecuador al siguiente nivel!</strong></Typography>
            <Box p={5}></Box>
            <Container>
                <Grid container spacing={5} direction="row" sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Grid item >
                        <a href="https://apps.apple.com/ec/app/polivoz/id6741082020" target="_blank" rel="noopener noreferrer">
                            <img loading='lazy' width={200} src={btnappstore} alt='app store polivoz' />
                        </a>
                    </Grid>
                    <Grid item >
                        <a href="https://play.google.com/store/apps/details?id=com.sebasmite.myapk" target="_blank" rel="noopener noreferrer">
                            <img width={220} src={btnplaystore} alt='play store polivoz' />
                        </a>
                    </Grid>
                </Grid>
            </Container>
            {/* <CheckoutForm /> */}
            <Footer />
        </Container>
    )
}